export type AppConfiguration = {
  WebsiteName?: string
  WebsiteBaseUrl?: string
  WebsiteDescription?: string
  CreateProfileStep?: boolean
  SelectPlanStep?: boolean
  BillingEnabled?: boolean
  BlogAvailable?: boolean
  StudioAvailable?: boolean
  PricingAvailable?: boolean
  AccountAreaEnabled?: boolean
  WaitingListEnabled?: boolean
  LoginAreaEnabled?: boolean
  MagicLinkLoginEnabled?: boolean
  RegistrationEnabled?: boolean
  LoginEnabled?: boolean
  ShowWaitingList?: boolean
}

import ConfigData from "./config.json"

export const AppConfig: AppConfiguration = ConfigData as AppConfiguration
